import React from 'react';
import logo from '../logo.svg';  // Import the logo
import backgroundImage from '../google-screenshot.png'; // Import your background image
import './Navbar.css';  // Import the CSS file



const Navbar = () => {
  return (
    <nav style={{...styles.nav, backgroundImage: `url(${backgroundImage})` }}>
        <div style={styles.logoContainer}>
        <img src={logo} alt="React Logo" style={styles.reactIcon} />
        <div style={styles.textContainer}>
          <h2 style={styles.subTitle2}>Devitables</h2>
          <p style={styles.question}>Is forensik.cc jQuery only because of their logo?</p>
        </div>
      </div>
     
    </nav>
  );
};

const styles = {
  nav: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    zIndex: '1',
   
    justifyContent: 'space-between', // This will push other elements to the right
   
    padding: '10px',
    paddingTop: '80px',
    paddingBottom: '80px',
    backgroundColor: '#4B658E', /* Dark blue from the sky in the image */
    color: 'white',

    
    backgroundSize: 'cover', // Make sure the image covers the navbar
    //backgroundPosition: 'center', // Center the image
    color: 'white',
  },
 
  logoContainer: {
    display: 'flex',
    alignItems: 'center', // Aligns the logo and subtitle vertically in the center
  },
  reactIcon: {
    marginLeft: 20,
    width: '150px', // Increase the width to make it larger
    height: '150px', // Adjust the height as well
    margin: '20px auto', // Center the icon with some margin
    display: 'block', // Ensure it's centered horizontally
    animation: 'spin infinite 1s linear',  // Add animation
  },
 
  container: {
    textAlign: 'center',
    marginRight: '30px'
  },

  textContainer: {
    display: 'flex',
    flexDirection: 'column', // Stack the subtitle and question vertically
    justifyContent: 'center',
    marginLeft: '20px',
  },

  subTitle2:{
    marginLeft: '0px',
    textAlign: 'left',
    fontSize: '2rem', // Slightly smaller subtitle
    color: '#F5EFE6',
    zIndex:'100px',
  },
  question: {
    fontSize: '1.2rem', // Smaller font size for the question
    color: '#F5EFE6',
    marginTop: '10px',
  },

  title: {
    fontSize: '5rem', // Big font size for the main title
    fontWeight: 'bold',
    margin: '0 0 0 10px',
    color: '#F5EFE6', // Customize the color as needed
  },
 
  subTitle: {
    fontSize: '2rem', // Slightly smaller subtitle
    color: '#F5EFE6',
    marginTop: '10px',
  },
  backgroundImage: {
    // Slightly smaller subtitle
    zIndex: '-1',
    marginTop: '10px',
    opacity: "50%"
  },
};



export default Navbar;


